export const DiscordIcon = ({ style }) => {
  return (
    <svg viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={style}>
      <path
        d="M22.6553 2.25786C20.943 1.45673 19.1122 0.874496 17.1981 0.542969C16.963 0.967974 16.6884 1.53962 16.499 1.99436C14.4643 1.68836 12.4483 1.68836 10.4509 1.99436C10.2616 1.53962 9.98075 0.967974 9.74358 0.542969C7.82741 0.874496 5.99445 1.45886 4.28218 2.2621C0.828493 7.48107 -0.107744 12.5704 0.360376 17.5875C2.65104 19.2981 4.87097 20.3372 7.05343 21.0172C7.5923 20.2756 8.07289 19.4872 8.48691 18.6563C7.69839 18.3567 6.94317 17.987 6.22957 17.5577C6.41888 17.4175 6.60406 17.2708 6.78297 17.12C11.1354 19.1557 15.8645 19.1557 20.1649 17.12C20.3459 17.2708 20.5311 17.4175 20.7183 17.5577C20.0026 17.9891 19.2453 18.3588 18.4568 18.6585C18.8708 19.4872 19.3494 20.2777 19.8903 21.0193C22.0749 20.3393 24.2969 19.3002 26.5875 17.5875C27.1368 11.7714 25.6492 6.72881 22.6553 2.25786ZM9.07987 14.502C7.77331 14.502 6.70182 13.2823 6.70182 11.7969C6.70182 10.3115 7.75043 9.08968 9.07987 9.08968C10.4094 9.08968 11.4808 10.3094 11.4579 11.7969C11.46 13.2823 10.4094 14.502 9.07987 14.502ZM17.868 14.502C16.5615 14.502 15.49 13.2823 15.49 11.7969C15.49 10.3115 16.5386 9.08968 17.868 9.08968C19.1975 9.08968 20.269 10.3094 20.2461 11.7969C20.2461 13.2823 19.1975 14.502 17.868 14.502Z"
        fill={style.includes('fill-white') ? 'fill-white' : 'url(#grad1)'}
      />
      <defs>
        <linearGradient id="grad1" x1="0%" y1="100%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#904AFF" />
          <stop offset="100%" stopColor="#67D7F9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
