import { Box, Button, Dialog, Link, Typography, useMediaQuery } from '@mui/material';

import { SCANNER_URL } from '../utils/links';
import environments from '../utils/environments';

const { NFT_ADDRESS, NETWORK_ID } = environments;

export default function MintCompleteDialog({ handleClose, quantity, txnHash }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Dialog maxWidth="md" onClose={handleClose} open={!!txnHash} PaperProps={{ sx: { bgcolor: 'transparent' } }}>
      {isSmall ? (
        <img
          src="/images/container-dialog-mobile.png"
          alt=""
          height="80vh"
          style={{ width: 'calc(80vh / 699 * 331)' }}
        />
      ) : (
        <img src="/images/container-dialog-desktop.png" alt="" width="800" />
      )}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        p={2}
        pt={1.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 3, lg: 1.5 }}>
        <Typography
          fontSize={{ xs: 24, md: 36, xl: 48 }}
          fontWeight={500}
          sx={{
            background: 'linear-gradient(to right, #904AFF 0%, #67D7F9 100%);',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
          Congratulations!
        </Typography>
        <Box width={20} height="1px" sx={{ background: 'linear-gradient(to right, #904AFF 0%, #67D7F9 100%);' }} />
        <Typography fontSize={{ xs: 20, md: 24 }} fontWeight={500} textAlign="center" color="white">
          You successfully bought {quantity} Gangster{quantity > 1 ? 's' : ''}
        </Typography>
        <Link
          color="#fff"
          component="a"
          href={`https://${SCANNER_URL[Number(NETWORK_ID)]}/tx/${txnHash}`}
          target="_blank"
          rel="noopener noreferrer">
          View Transaction
        </Link>
        <Typography fontSize={{ xs: 14, sm: 16, lg: 20 }} color="white" textAlign="center">
          Gangsters are playable as soon as Gangster Arena 2 launches on Blast Mainnet. Stay notified on Twitter.
        </Typography>
        <Box display="flex" flexDirection={{ xs: 'column-reverse', lg: 'row' }} gap={{ xs: 1, lg: 3 }}>
          <Box
            height="100%"
            maxHeight="56px"
            py={2}
            px={4}
            bgcolor="#68abc4"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              transition: 'all ease 0.3s',
              cursor: 'pointer',
              clipPath:
                'polygon(0px 0%, calc(100% - 20px) 0%, 100% calc(10px), 100% calc(100% + 0px), calc(100% - 20px) 100%, calc(20px) 100%, 0% calc(100% - 10px), 0% calc(10px))',
              '&:hover': { bgcolor: '#5b96ac' },
            }}
            component="a"
            href={`https://opensea.io/assets/blast/${NFT_ADDRESS}/1`}
            target="_blank"
            rel="noopener noreferrer">
            <Typography fontSize={{ xs: 12, lg: 16 }} fontWeight={300} color="white" textTransform="uppercase">
              VIEW IN MARKETPLACE
            </Typography>
          </Box>
          <Box
            height="100%"
            maxHeight="56px"
            py={2}
            px={4}
            bgcolor="#904aff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              transition: 'all ease 0.3s',
              cursor: 'pointer',
              clipPath:
                'polygon(0px 0%, calc(100% - 20px) 0%, 100% calc(10px), 100% calc(100% + 0px), calc(100% - 20px) 100%, calc(20px) 100%, 0% calc(100% - 10px), 0% calc(10px))',
              '&:hover': { bgcolor: '#7b1ee3' },
            }}
            component="a"
            href="https://twitter.com/gguncharted"
            target="_blank"
            rel="noopener noreferrer">
            <Typography fontSize={{ xs: 12, lg: 16 }} fontWeight={300} color="white" textTransform="uppercase">
              FOLLOW TWITTER
            </Typography>
          </Box>
        </Box>
        {!isSmall && (
          <Button
            sx={{
              fontSize: 12,
              color: '#bbb',
              textDecoration: 'underline !important',
              '&:hover': { textDecorationThickness: '1.5px !important' },
            }}
            onClick={handleClose}>
            Close
          </Button>
        )}
      </Box>
    </Dialog>
  );
}
