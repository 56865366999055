const environments = {
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  MINTER_ADDRESS: process.env.REACT_APP_MINTER_ADDRESS,
  NFT_ADDRESS: process.env.REACT_APP_NFT_ADDRESS,
};

export default environments;
