import { createContext } from 'react';

import useWallet from '../hooks/useWallet';
import useMenu from '../hooks/useMenu';
import useUser from '../hooks/useUser';
import usePhase from '../hooks/usePhase';
import useWhitelistedPhaseIds from '../hooks/useWhitelistedPhaseIds';
import useEthPrice from '../hooks/useEthPrice';
import useConnectWallet from '../hooks/useConnectWallet';
import useSmartContract from '../hooks/useSmartContract';
import { toLowerCase } from '../utils/strings';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const walletState = useWallet();
  const menuState = useMenu();
  const userState = useUser();
  const { whitelistedPhaseIds } = useWhitelistedPhaseIds(userState?.user?.username);
  const phaseState = usePhase({
    whitelistedPhaseIds,
    logged: !!userState.user,
  });
  const ethPriceState = useEthPrice();
  const connectWalletState = useConnectWallet(userState.initialized, userState.user);
  const provider =
    toLowerCase(userState.user?.id) === toLowerCase(connectWalletState.address)
      ? connectWalletState.provider
      : walletState.provider;
  const checkNetwork =
    toLowerCase(userState.user?.id) === toLowerCase(connectWalletState.address)
      ? connectWalletState.checkNetwork
      : walletState.checkNetwork;
  const smartContractState = useSmartContract({ provider, checkNetwork, address: userState.user?.id });

  return (
    <AppContext.Provider
      value={{
        walletState,
        connectWalletState,
        menuState,
        userState,
        phaseState,
        ethPriceState,
        smartContractState,
      }}>
      {children}
    </AppContext.Provider>
  );
};
