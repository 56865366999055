import Navigations from './navigations';

import './assets/styles/global.css';
import './assets/styles/animation.css';
import './assets/styles/content.css';
import './assets/styles/clip.css';
import './assets/styles/swiper.css';

const App = () => {
  return <Navigations />;
};

export default App;
