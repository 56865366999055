import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { parseEther } from '@ethersproject/units';

import Minter from '../assets/abis/Minter.json';
import NFT from '../assets/abis/NFT.json';
import environments from '../utils/environments';

const { MINTER_ADDRESS, NFT_ADDRESS } = environments;

const useSmartContract = ({ address, provider, checkNetwork }) => {
  const [nftBalance, setNftBalance] = useState(0);

  useEffect(() => {
    if (address) getNFTBalance(address);
    else setNftBalance(0);
  }, [address]);

  const getSigner = () => {
    if (!provider) return null;

    const web3Provider = new Web3Provider(provider);
    const signer = web3Provider.getSigner();
    return signer;
  };

  const getMinterContract = () => {
    const signer = getSigner();
    if (!signer) return null;

    return new Contract(MINTER_ADDRESS, Minter.abi, signer);
  };

  const getNFTContract = () => {
    const web3Provider = new Web3Provider(provider);

    return new Contract(NFT_ADDRESS, NFT.abi, web3Provider);
  };

  const mint = async ({ phaseId, amount, signature, value }) => {
    try {
      await checkNetwork();
    } catch (err) {
      const newErr = new Error('Wrong network');
      newErr.code = '0001';
      throw newErr;
    }

    const web3Provider = new Web3Provider(provider);
    const accs = await web3Provider.listAccounts();
    if (accs.length === 0) await provider.request({ method: 'eth_requestAccounts' });
    const minterContract = getMinterContract();
    const tx = await minterContract.mint(phaseId, amount, signature, { value: parseEther(`${value}`) });
    const receipt = await tx.wait();
    getNFTBalance(address);
    return receipt;
  };

  const getNFTBalance = async (add) => {
    await checkNetwork();
    const nftContract = getNFTContract();

    const balance = await nftContract.balanceOf(add, 1);
    setNftBalance(Number(balance));
  };

  return { mint, nftBalance };
};

export default useSmartContract;
