export const MediumIcon = ({ style }) => {
  return (
    <svg viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={style}>
      <path
        d="M10.8256 0.5C16.3109 0.5 20.7572 4.9774 20.7572 10.5002C20.7572 16.0229 16.3105 20.5 10.8256 20.5C5.34059 20.5 0.893555 16.0229 0.893555 10.5002C0.893555 4.9774 5.34025 0.5 10.8256 0.5ZM26.6863 1.08578C29.4289 1.08578 31.6523 5.30032 31.6523 10.5002H31.6526C31.6526 15.6987 29.4293 19.9146 26.6866 19.9146C23.944 19.9146 21.7206 15.6987 21.7206 10.5002C21.7206 5.30167 23.9436 1.08578 26.6863 1.08578ZM34.3625 2.06636C35.3269 2.06636 36.1091 5.84224 36.1091 10.5002C36.1091 15.1567 35.3273 18.934 34.3625 18.934C33.3978 18.934 32.6163 15.1578 32.6163 10.5002C32.6163 5.84258 33.3982 2.06636 34.3625 2.06636Z"
        fill={style.includes('fill-white') ? 'fill-white' : 'url(#grad1)'}
      />
    </svg>
  );
};
