import { useRef } from 'react';
import { Box, Link, Typography, SvgIcon } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LanguageIcon from '@mui/icons-material/Language';
import XIcon from '@mui/icons-material/X';

import Phase from './Phase';
import useAppContext from '../../../contexts/useAppContext';
import { socials } from '../../../utils/links';

const DiscordIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M 20.332031 4.226562 C 18.777344 3.5 17.117188 2.972656 15.378906 2.671875 C 15.164062 3.058594 14.917969 3.574219 14.746094 3.988281 C 12.898438 3.710938 11.070312 3.710938 9.257812 3.988281 C 9.085938 3.574219 8.832031 3.058594 8.617188 2.671875 C 6.875 2.972656 5.214844 3.503906 3.660156 4.230469 C 0.527344 8.96875 -0.324219 13.585938 0.101562 18.136719 C 2.179688 19.6875 4.195312 20.632812 6.175781 21.25 C 6.664062 20.574219 7.097656 19.859375 7.476562 19.105469 C 6.757812 18.835938 6.074219 18.5 5.425781 18.109375 C 5.597656 17.980469 5.765625 17.847656 5.929688 17.710938 C 9.878906 19.558594 14.167969 19.558594 18.070312 17.710938 C 18.234375 17.847656 18.402344 17.980469 18.574219 18.109375 C 17.921875 18.5 17.238281 18.835938 16.519531 19.109375 C 16.898438 19.859375 17.332031 20.578125 17.820312 21.25 C 19.804688 20.632812 21.820312 19.691406 23.898438 18.136719 C 24.394531 12.859375 23.046875 8.285156 20.332031 4.226562 Z M 8.011719 15.335938 C 6.828125 15.335938 5.855469 14.230469 5.855469 12.882812 C 5.855469 11.535156 6.808594 10.425781 8.011719 10.425781 C 9.21875 10.425781 10.191406 11.53125 10.171875 12.882812 C 10.171875 14.230469 9.21875 15.335938 8.011719 15.335938 Z M 15.988281 15.335938 C 14.800781 15.335938 13.828125 14.230469 13.828125 12.882812 C 13.828125 11.535156 14.78125 10.425781 15.988281 10.425781 C 17.191406 10.425781 18.164062 11.53125 18.144531 12.882812 C 18.144531 14.230469 17.191406 15.335938 15.988281 15.335938 Z M 15.988281 15.335938 " />
  </SvgIcon>
);

const links = [
  { Icon: DiscordIcon, url: socials[1].url },
  { Icon: XIcon, url: socials[2].url },
  { Icon: LanguageIcon, url: 'https://uncharted.gg' },
];

const MainContent = () => {
  const {
    phaseState: { phases, updatePhaseStatus },
    ethPriceState: { ethPrice },
    userState: { user },
    smartContractState: { nftBalance },
  } = useAppContext();
  const moreInformationRef = useRef();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={3}>
        {links.map((link) => (
          <a key={link.url} href={link.url} target="_blank" rel="noopener noreferrer">
            <link.Icon sx={{ color: '#904aff', transition: 'all 0.3s ease', '&:hover': { color: 'white' } }} />
          </a>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize={{ xs: 32, md: 48, xl: 56 }} fontWeight={500} color="white">
            Gangster NFT Presale
          </Typography>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography color="white" fontSize={{ xs: 16, sm: 20 }}>
              Gangsters are the core unit in Gangster Arena
            </Typography>
            <Typography color="white" fontSize={{ xs: 16, sm: 20 }}>
              They passively generate the $GREED token and are used to compete in gang wars and leaderboard rewards.
            </Typography>
            <Typography color="white" fontSize={{ xs: 16, sm: 20 }}>
              The ONLY way to generate $GREED when Gangster Arena 2 begins will be to own a Gangster.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ cursor: 'pointer' }}
            onClick={() => moreInformationRef.current?.scrollIntoView?.()}>
            <Typography fontSize={{ xs: 12, sm: 14 }} fontWeight={700} color="white">
              Read more
            </Typography>
            <ArrowRightAltIcon sx={{ color: '#904AFF' }} />
          </Box>
        </Box>
        <Box my={1} display="flex" alignItems="center">
          <Typography color="white" fontSize={{ xs: 20, sm: 24 }}>
            You own: {nftBalance} Gangster{nftBalance > 1 ? 's' : ''}
          </Typography>
        </Box>
      </Box>
      <Box py={2} display="flex" flexDirection="column" gap={2}>
        {phases.map((phase) => (
          <Phase
            key={phase.id}
            phase={phase}
            updatePhaseStatus={updatePhaseStatus}
            ethPrice={ethPrice}
            minted={user?.mintedPhases?.[`${phase.id}`] || 0}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} ref={moreInformationRef}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography fontSize={{ xs: 24, md: 32, xl: 40 }} fontWeight={500} color="white">
            More
          </Typography>
          <Typography
            fontSize={{ xs: 24, md: 32, xl: 40 }}
            fontWeight={500}
            sx={{
              background: 'linear-gradient(to right, #904AFF 0%, #67D7F9 100%);',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Information
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography fontSize={{ xs: 16, sm: 20 }} color="white">
            Gangster Arena 2 is an idle risk-to-earn game available on mobile and web. Play as a mob boss and expand
            your gang. Earn passive rewards and climb the leaderboards. Battle it out in PVP mind games, both in gang
            wars and out in the marketplace.
          </Typography>
          <Typography fontSize={{ xs: 16, sm: 20 }} color="white">
            Gangsters are access NFTs with an uncapped supply (required to play Gangster Arena 2). Gangsters cost
            $GREED, and the only way to generate $GREED when the game begins is to own a Gangster.
          </Typography>
          <Typography fontSize={{ xs: 16, sm: 20 }} color="white">
            For more information please visit our{' '}
            <Link href={links[1].url} target="_blank" rel="noopener noreferrer" sx={linkStyle}>
              Twitter
            </Link>{' '}
            and{' '}
            <Link href={links[0].url} target="_blank" rel="noopener noreferrer" sx={linkStyle}>
              Discord
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MainContent;

const linkStyle = {
  background: 'linear-gradient(to right, #904AFF 0%, #67D7F9 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(to right, #67D7F9 0%, #904AFF 100%, #67D7F9 0%, #904AFF 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundSize: '200% 200%',
    animation: 'gradient 1.5s ease infinite',
  },
};
